import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInValidationSchema } from "../validation";
import { SignInFormValues } from "../types";
import { Link } from "react-router-dom";
import { fieldNames } from "../data";

import {
  logInWithEmailAndPassword,
  logInWithGoogle,
} from "../../../../shared/infrastructure/firebase";
import Logo from "../../../../shared/assets/logo.svg";
import googleIconImageSrc from "../../../../shared/assets/google.png";
import Spinner from "../../../../shared/components/spinner/Spinner";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";

const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({
    resolver: yupResolver(signInValidationSchema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ email, password }: SignInFormValues) => {
    setLoading(true);

    try {
      await logInWithEmailAndPassword({ email: email, password: password });
    } catch (e) {}
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    await logInWithGoogle();
  };

  return (
    <div className="min-h-screen flex-wrap flex flex-col items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Meetmore" />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex space-y-4 flex-col items-center">
                <button
                  type="button"
                  className="w-full  font-semibold rounded-lg py-2 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm "
                  onClick={handleGoogleLogin}
                >
                  <span className="iconContainer">
                    <img src={googleIconImageSrc} className="w-6" alt="" />
                  </span>
                  <span className="ml-2">Sign in with Google</span>
                </button>
              </div>
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">Or continue with</span>
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor={fieldNames.email}
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    {...register("email")}
                    placeholder="Email address"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                  {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                </div>
              </div>
              <div>
                <label htmlFor={"password"} className="block text-sm font-medium text-gray-700">
                  Password{" "}
                </label>
                <div className="mt-1">
                  <input
                    {...register("password")}
                    placeholder="Password"
                    type="password"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                  {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
                </div>
              </div>

              <div>
                <button
                  disabled={loading ? true : false}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <svg
                        className="h-5 w-5 text-orange-500 group-hover:text-orange-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    )}
                  </span>
                  {loading ? "Loading" : "Sign In"}
                </button>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-sm leading-6">
                  <Link
                    to={"./forgot-password"}
                    className="font-semibold text-orange-600 hover:text-orange-500"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-center mt-4">
                <div className="text-sm">
                  <span className="text-gray-600"></span>
                  <Link
                    to="/sign-up"
                    className="font-semibold text-orange-600 hover:text-orange-500"
                  >
                    Don't have an account? Sign Up
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
