import { UseFormRegister, FieldErrorsImpl, UseFormSetValue, UseFormTrigger, UseFormGetValues } from "react-hook-form";
import { NewClientValues } from "../validation";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

interface ContactDetailsProps {
  currentStep: number;
  nextStep: () => Promise<void>;
  previousStep: () => Promise<void>;
  register: UseFormRegister<NewClientValues>;
  errors: FieldErrorsImpl<NewClientValues>;
  setValue: UseFormSetValue<NewClientValues>;
  trigger: UseFormTrigger<NewClientValues>;
  onSubmitPostalCodesAndContactDetails: (values: NewClientValues) => void;
  getValues: UseFormGetValues<NewClientValues>
}

const ContactDetails = ({ currentStep, nextStep, previousStep, register, errors, trigger, getValues, onSubmitPostalCodesAndContactDetails }: ContactDetailsProps) => {
  const submitAndNext = async () => {
    const results = await trigger(["email", "cellNumber"]);
    if (results) {
      onSubmitPostalCodesAndContactDetails(getValues());
    }
  };

  return (
    <div className="min-h-screen flex h-full flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl w-full mx-auto space-y-8">
        <h2 className="text-center text-3xl font-bold text-gray-900">
          {currentStep}. How can we <span className="inline text-orange-500">contact</span> you?
        </h2>
        <div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-orange-700">
                Please provide us with your contact details so we can get in touch with you.
              </p>
            </div>
          </div>
        </div>
        
        <div className="bg-white shadow-md rounded-lg px-4 py-6 sm:p-6 space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <input
              id="email"
              type="text"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              placeholder="you@example.com"
              {...register("email", { required: "Email is required" })}
            />
            {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>}
          </div>
          <div>
            <label htmlFor="cellNumber" className="block text-sm font-medium text-gray-700">
              Mobile Number
            </label>
            <input
              id="cellNumber"
              type="text"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              placeholder="+4401234567890"
              {...register("cellNumber", { required: "Mobile number is required" })}
            />
            {errors.cellNumber && <p className="mt-2 text-sm text-red-600">{errors.cellNumber.message}</p>}
          </div>
        </div>

        <div className="flex space-x-6 mt-6">
          <button
            type="button"
            onClick={() => previousStep()}
            className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => submitAndNext()}
            className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
