import Logo from "../../assets/logo.svg";

const PendingApproval = () => {
  return (
    <div className="min-h-screen flex-wrap flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Meetmore" />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            You are currently on the waitlist
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="text-center">
              <p className="text-gray-600">
                Your workspace is currently pending approval.
              </p>
              <p className="text-gray-600 mt-4">
                Please be patient while we process your request.
              </p>
              <p className="text-gray-600 mt-4">
                You'll be notified once your workspace is activated.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingApproval;
