import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import { Badge } from "../../../../shared/components/badge/Badge";
import {
  CAMPAIGN_LEAD_PROFILE_OUTREACH_STATUS,
  SALE_ACTION_STATUS,
} from "../../../../shared/constants/models";
import { ArrayElement, formatters } from "../../../../shared/utils";
import { TableColumnHeader } from "../../../../shared/components/table/TableColumnHeader";
import { capitalize } from "lodash";
import { Button } from "../../../../shared/components/button/Button";
import { Ellipsis, Mail } from "lucide-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { UseProspectedCampaignLeadProfiles } from "../../../../shared/domains/campaigns/graphql/hooks/useProspectedCampaignLeadProfiles";

dayjs.extend(relativeTime);

const columnHelper =
  createColumnHelper<
    ArrayElement<UseProspectedCampaignLeadProfiles["prospectedCampaignLeadProfiles"]>
  >();

export const getColumns = ({
  onEditClick,
}: {
  onEditClick: (
    row: Row<ArrayElement<UseProspectedCampaignLeadProfiles["prospectedCampaignLeadProfiles"]>>
  ) => void;
}) => {
  return [
    columnHelper.accessor("digital_profile.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Name" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Name",
      },
      id: "digital_profile.name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            {row.original.numberOfUnreadMessages === 0 && (
              <span className="font-normal"> {row.original.digital_profile.name}</span>
            )}
            {row.original.numberOfUnreadMessages > 0 && (
              <>
                <span className="font-medium"> {row.original.digital_profile.name}</span>
                <div className="ml-2 relative">
                  <Mail width={18} height={18} />
                  <span className="absolute -top-1.5 -right-1.5 bg-orange-500 text-white text-xs font-medium rounded w-3.5 h-3.5 flex items-center justify-center">
                    {row.original.numberOfUnreadMessages}
                  </span>
                </div>
              </>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("conversation_value", {
      header: ({ column }) => <TableColumnHeader column={column} title="Value" />,
      enableSorting: true,
      meta: {
        displayName: "Value",
      },
      cell: ({ getValue }) => {
        return <span className="font-medium">{formatters.currency({ number: getValue() })}</span>;
      },
    }),
    columnHelper.accessor("last_sale_action_status", {
      header: ({ column }) => <TableColumnHeader column={column} title="Sale Status" />,
      enableSorting: true,
      meta: {
        displayName: "Sale Status",
      },
      cell: ({ row }) => {
        const statusId = row.original.last_sale_action_status?.id;
        const statusName = row.original.last_sale_action_status?.name;

        if (!statusId) {
          return <Badge variant={"neutral"}>No Status</Badge>;
        }

        if (statusId && [SALE_ACTION_STATUS.DEAL_LOST].includes(statusId)) {
          return <Badge variant={"error"}>{statusName}</Badge>;
        }

        if (
          statusId &&
          [SALE_ACTION_STATUS.QUALIFIED, SALE_ACTION_STATUS.INTERESTED].includes(statusId)
        ) {
          return <Badge variant={"warning"}>{statusName}</Badge>;
        }

        if (
          statusId &&
          [
            SALE_ACTION_STATUS.CALL_BOOKED,
            SALE_ACTION_STATUS.VALUATION_WON,
            SALE_ACTION_STATUS.INSTRUCTION_WON,
            SALE_ACTION_STATUS.SSTC,
            SALE_ACTION_STATUS.SOLD,
            SALE_ACTION_STATUS.UNKNOWN,
            SALE_ACTION_STATUS.UNDER_OFFER,
            SALE_ACTION_STATUS.QUALIFIED_LOW_INTEREST,
          ].includes(statusId)
        ) {
          return <Badge variant={"success"}>{statusName}</Badge>;
        }
      },
    }),
    columnHelper.accessor("campaign_lead_profile_outreach_status", {
      header: ({ column }) => <TableColumnHeader column={column} title="Outreach Status" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Outreach Status",
      },
      cell: ({ row }) => {
        if (!row.original.campaign_lead_profile_outreach_status?.id) {
          return <span>No Status</span>;
        }

        if (
          row.original.campaign_lead_profile_outreach_status?.id ===
          CAMPAIGN_LEAD_PROFILE_OUTREACH_STATUS.DISCOVERED
        ) {
          return <span>Discovered</span>;
        }

        if (
          row.original.campaign_lead_profile_outreach_status?.id ===
          CAMPAIGN_LEAD_PROFILE_OUTREACH_STATUS.CONNECTION_PENDING
        ) {
          return <span>Connection Pending</span>;
        }

        if (
          row.original.campaign_lead_profile_outreach_status?.id ===
          CAMPAIGN_LEAD_PROFILE_OUTREACH_STATUS.CONNECTED
        ) {
          return <span>Connected</span>;
        }

        if (
          row.original.campaign_lead_profile_outreach_status?.id ===
          CAMPAIGN_LEAD_PROFILE_OUTREACH_STATUS.REPLIED
        ) {
          return <span>Replied</span>;
        }
      },
    }),
    columnHelper.accessor("campaign_lead.campaign.campaign_type.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Campaign Type" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Campaign Type",
      },
    }),

    columnHelper.accessor("is_incorrect_match", {
      header: ({ column }) => <TableColumnHeader column={column} title="Match Status" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Match Status",
      },
      cell: ({ row }) => {
        const statusValue = row.original.is_incorrect_match;

        if (statusValue === null || statusValue === undefined) {
          return <Badge variant={"neutral"}>Unknown</Badge>;
        }

        if (statusValue === false) {
          return <Badge variant={"success"}>Correct</Badge>;
        }

        if (statusValue === true) {
          return <Badge variant={"error"}>Incorrect</Badge>;
        }
      },
    }),
    columnHelper.accessor("conversation_thread.last_inbound_message.external_created_at", {
      header: ({ column }) => <TableColumnHeader column={column} title="Last reply" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Last reply",
      },
      cell: ({ row }) => {
        return (
          <span>
            {row.original.lastReplyExternalCreatedAt
              ? dayjs(row.original.lastReplyExternalCreatedAt).fromNow()
              : "n/a"}
          </span>
        );
      },
    }),
    columnHelper.accessor("conversation_thread.is_managed_by_user", {
      header: ({ column }) => <TableColumnHeader column={column} title="Handler" />,
      enableSorting: true,
      enableHiding: false,
      id: "conversation_thread.user.email",
      meta: {
        displayName: "Handler",
      },
      cell: ({ row }) => {
        const userEmail = row.original.conversation_thread?.is_managed_by_user?.email;

        if (!userEmail) {
          return <span>None</span>;
        }
        return <span>{userEmail}</span>;
      },
    }),
    columnHelper.accessor("action", {
      header: ({ column }) => <TableColumnHeader column={column} title="Action" />,
      enableSorting: true,
      meta: {
        displayName: "Action",
      },
      cell: ({ row }) => {
        return <span className="font-medium">{row.original.action ?? "n/a"}</span>;
      },
    }),
    columnHelper.accessor("action_due_date", {
      header: ({ column }) => <TableColumnHeader column={column} title="Action due date" />,
      enableSorting: true,
      meta: {
        displayName: "Action due date",
      },
      cell: ({ row }) => {
        return (
          <span>
            {row.original.action_due_date ? dayjs(row.original.action_due_date).fromNow() : "n/a"}
          </span>
        );
      },
    }),
    columnHelper.display({
      id: "edit",
      header: "Edit",
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Edit",
      },
      cell: ({ row }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => onEditClick?.(row)}
            className="group aspect-square p-1.5 hover:border hover:border-gray-300 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
          >
            <Ellipsis
              className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-data-[state=open]:text-gray-700 group-hover:dark:text-gray-300 group-data-[state=open]:dark:text-gray-300"
              aria-hidden="true"
            />
          </Button>
        );
      },
    }),
  ] as ColumnDef<
    ArrayElement<UseProspectedCampaignLeadProfiles["prospectedCampaignLeadProfiles"]>
  >[];
};
