import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon, InformationCircleIcon } from "@heroicons/react/24/solid";

interface LinkedInDisclaimerProps {
    currentStep: number;
    nextStep: () => Promise<void>;
    previousStep: () => Promise<void>;
  }
  
  const LinkedInDisclaimer = ({ currentStep, nextStep, previousStep }: LinkedInDisclaimerProps) => {
		return (
			<div className="bg-gray-50 pb-20 min-h-screen h-full flex-wrap flex flex-col items-center justify-center focus:outline-none">
				<div className="relative w-full mx-auto px-8 pt-10 pb-8 md:px-10 xl:px-12">
					<div className="max-w-3xl mx-auto space-y-8">
						<div>
							<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
								{currentStep}.  We Need Your <span className="text-orange-600">LinkedIn Credentials</span>
							</h2>
		
							<div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
								<div className="flex">
									<div className="flex-shrink-0">
										<InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
									</div>
									<div className="ml-3 flex-1 md:flex md:justify-between">
										<p className="text-sm text-orange-700">
											To get started on your first campaign, we’ll need access to your LinkedIn account. In the next step, we’ll ask for your email and password.
										</p>
									</div>
								</div>
							</div>
							<div className="mt-6 bg-white shadow-md rounded-lg px-4 py-6 sm:p-6 space-y-4">
								<Disclosure as="div" className="border-b border-gray-200 py-6">
									{({ open }) => (
										<>
											<Disclosure.Button className="flex w-full justify-between text-left text-gray-900">
												<span>Why We Need Your LinkedIn Credentials</span>
												{open ? <MinusIcon className="h-5 w-5 text-gray-400" /> : <PlusIcon className="h-5 w-5 text-gray-400" />}
											</Disclosure.Button>
											<Disclosure.Panel className="mt-2 text-gray-700">
												<p>
													To help you generate more qualified leads, we send outreach messages directly from your LinkedIn account. 
													Think of us as a junior team member managing your lead generation—just as you might have someone handle your social media. 
													<br/><br/>Your existing LinkedIn messages will remain private and untouched, as we can only interact with conversations we initiate on your behalf.
												</p>
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>		
								<Disclosure as="div" className="border-b border-gray-200 py-6">
									{({ open }) => (
										<>
											<Disclosure.Button className="flex w-full justify-between text-left text-gray-900">
												<span>Security of Your Information</span>
												{open ? <MinusIcon className="h-5 w-5 text-gray-400" /> : <PlusIcon className="h-5 w-5 text-gray-400" />}
											</Disclosure.Button>
											<Disclosure.Panel className="mt-2 text-gray-700">
												<p>
													Your LinkedIn credentials are securely stored in a 1Password vault, protected by military-grade encryption, ensuring your data remains safe and private at all times.
												</p>
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							</div>
						</div>
					</div>
					<div className="max-w-3xl mx-auto">
						<div className="py-3 mt-4">
							<div className="flex space-x-6">
								<button
									type="button"
									onClick={nextStep}
									className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
								>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
  };
  
  export default LinkedInDisclaimer;
