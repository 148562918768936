import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../infrastructure/firebase";
import { useCurrentUserContext } from "../domains/users/graphql/hooks/useCurrentUserContext";
import AccessDenied from "../../modules/authentication/accessDenied/page/AccessDenied";
import PendingApproval from "../components/pendingApproval/PendingApproval";

interface AuthenticatedRouteProps {
  element: ReactElement;
}

const AuthenticatedRoute = ({ element }: AuthenticatedRouteProps) => {
  const [user, loading] = useAuthState(auth);
  const { canAccessTheApp, loading: isUserAdminLoading, currentWorkspacePending } = useCurrentUserContext({
    externalId: user?.uid || "",
  });

  if (loading || isUserAdminLoading) {
    return null; // Or a loading spinner
  }

  if (user && canAccessTheApp && !currentWorkspacePending) {
    return element;
  } else if (user && !canAccessTheApp) {
    return <AccessDenied />;
  }
    else if (user && currentWorkspacePending) {
      return <PendingApproval />;
  } else {
    return <Navigate to="/" />;
  }
};

export default AuthenticatedRoute;
