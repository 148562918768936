import { UseMutateAsyncFunction } from "react-query";
import { TagCampaignLeadProfileBody } from "../../../../shared/domains/campaigns/rest/tagCampaignLeadProfileAPI";
import { useSaleActionStatuses } from "../../../../shared/domains/static/graphql/hooks/useSaleActionStatuses";
import { ConversationEditFields } from "../types";
import { Controller, useForm } from "react-hook-form";
import {
  updateBooleanFieldSchema,
  UpdateBooleanFieldValues,
  updateDateFieldSchema,
  UpdateDateFieldValues,
  updateSaleActionStatusSchema,
  UpdateSaleActionStatusValues,
  UpdateStringFieldValues,
} from "../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseCampaignLeadProfileById } from "../../../../shared/domains/campaigns/graphql/hooks/useCampaignLeadProfileById";
import { SALE_ACTION_STATUS_SOURCE } from "../../../../shared/constants/models";
import { Button } from "../../../../shared/components/button/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import {
  CORRECT_MATCH_OPTIONS,
  DO_NOT_MESSAGE_OPTIONS,
  PERSONAL_CONVERSATION_OPTIONS,
} from "../data";
import { DatePicker } from "../../../../shared/components/datePicker/DatePicker";

interface EditActionDueDateProps {
  setIsEditingField: React.Dispatch<React.SetStateAction<ConversationEditFields | undefined>>;
  tagCampaignLeadProfileMutation: UseMutateAsyncFunction<
    any,
    any,
    TagCampaignLeadProfileBody,
    void
  >;
  tagCampaignLeadProfileMutationLoading: boolean;
  campaignLeadProfileId: number;
  existingActionDueDate?: Date;
}

const EditActionDueDate = ({
  tagCampaignLeadProfileMutation,
  tagCampaignLeadProfileMutationLoading,
  setIsEditingField,
  campaignLeadProfileId,
  existingActionDueDate,
}: EditActionDueDateProps) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<UpdateDateFieldValues>({
    resolver: yupResolver(updateDateFieldSchema),
  });

  const date = watch("field");

  const onSubmit = async (data: UpdateDateFieldValues) => {
    await tagCampaignLeadProfileMutation({
      campaignLeadProfileId: campaignLeadProfileId,
      actionDueDate: data.field as unknown as Date,
      propertyValues: [],
      saleActionStatuses: [],
    });
    setIsEditingField(undefined);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4  space-y-4">
        <div>
          <Controller
            control={control}
            name="field"
            render={({ field }) => {
              return (
                <DatePicker
                  defaultValue={existingActionDueDate ? new Date(existingActionDueDate) : undefined}
                  value={field.value}
                  onChange={field.onChange}
                  className="w-full"
                />
              );
            }}
          ></Controller>
          {errors.field && <ErrorMessage>{errors.field.message}</ErrorMessage>}
        </div>

        <div className="mt-6 flex justify-between">
          <Button
            onClick={() => setIsEditingField(undefined)}
            type="button"
            className="mt-2 w-full sm:mt-0 sm:w-fit"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={tagCampaignLeadProfileMutationLoading}
            className="w-full sm:w-fit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditActionDueDate;
