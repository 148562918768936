import { useQuery } from "@apollo/client";
import { getUserWorkspacesByExternalId } from "../queries/getUserWorkspacesByExternalId";
import { GetUserWorkspacesByExternalIdQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { WORKSPACE_STATUS } from "../../../../constants/models";

export const useCurrentUserContext = ({ externalId }: { externalId: string }) => {
  const { data, loading, error } = useQuery<GetUserWorkspacesByExternalIdQuery>(
    getUserWorkspacesByExternalId,
    {
      variables: { externalId },
    }
  );

  const hasOneWorkspace = data && data.workspace_user && Array.isArray(data.workspace_user) && data.workspace_user.length === 1;
  const currentWorkspacePending =
    hasOneWorkspace
      ? data.workspace_user[0].workspace.workspace_status_id === WORKSPACE_STATUS.PENDING
      : false;

  return {
    currentWorkspaceId:
      data &&
      data.workspace_user &&
      Array.isArray(data.workspace_user) &&
      data.workspace_user.length > 0
        ? data.workspace_user[0].workspace_id
        : null,
    canAccessTheApp: hasOneWorkspace,
    currentWorkspacePending: currentWorkspacePending,
    loading,
    error,
  };
};
