import * as yup from "yup";
export const signUpValidationSchema = yup.object().shape({
  email: yup.string().email("Please provide a valid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/,
      "Must Contain 8-64 Characters, At least One Uppercase, One Lowercase and One Number"
    ),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  terms: yup.boolean().oneOf([true], "Terms and Conditions were not accepted"),
});
