import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export interface TagCampaignLeadProfileBody {
  campaignLeadProfileId: number;
  saleActionStatuses: {
    id?: number;
    saleActionStatusId: number;
    sourceId: number;
  }[];
  propertyValues: {
    id?: number;
    propertyValue: number;
    sourceId: number;
  }[];
  isIncorrectMatch?: boolean | null;
  isDoNotMessage?: boolean | null;
  isPersonalConnection?: boolean | null;
  note?: string;
  actionDueDate?: Date | null;
  action?: string;
}

export const tagCampaignLeadProfileAPI = async (data: TagCampaignLeadProfileBody) => {
  const response = await meetmoreAPI.patch(
    `/campaign-lead-profiles/${data.campaignLeadProfileId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const useTagCampaignLeadProfileAPI = () => {
  let loadingToastId: string;

  return useMutation(tagCampaignLeadProfileAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Updating Conversation Tags...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Conversation Tags Updated!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
