import { UseMutateAsyncFunction } from "react-query";
import { TagCampaignLeadProfileBody } from "../../../../shared/domains/campaigns/rest/tagCampaignLeadProfileAPI";
import { useSaleActionStatuses } from "../../../../shared/domains/static/graphql/hooks/useSaleActionStatuses";
import { ConversationEditFields } from "../types";
import { Controller, useForm } from "react-hook-form";
import {
  updateBooleanFieldSchema,
  UpdateBooleanFieldValues,
  updateSaleActionStatusSchema,
  UpdateSaleActionStatusValues,
  updateStringFieldSchema,
  UpdateStringFieldValues,
} from "../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseCampaignLeadProfileById } from "../../../../shared/domains/campaigns/graphql/hooks/useCampaignLeadProfileById";
import { SALE_ACTION_STATUS_SOURCE } from "../../../../shared/constants/models";
import { Button } from "../../../../shared/components/button/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import {
  CORRECT_MATCH_OPTIONS,
  DO_NOT_MESSAGE_OPTIONS,
  PERSONAL_CONVERSATION_OPTIONS,
} from "../data";
import { Label } from "../../../../shared/components/label/Label";
import { Textarea } from "../../../../shared/components/textArea/Textarea";

interface EditNoteProps {
  setIsEditingField: React.Dispatch<React.SetStateAction<ConversationEditFields | undefined>>;
  tagCampaignLeadProfileMutation: UseMutateAsyncFunction<
    any,
    any,
    TagCampaignLeadProfileBody,
    void
  >;
  tagCampaignLeadProfileMutationLoading: boolean;
  campaignLeadProfileId: number;
  existingNote?: string;
}

const EditNote = ({
  tagCampaignLeadProfileMutation,
  tagCampaignLeadProfileMutationLoading,
  setIsEditingField,
  campaignLeadProfileId,
  existingNote,
}: EditNoteProps) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateStringFieldValues>({
    resolver: yupResolver(updateStringFieldSchema),
  });

  const onSubmit = async (data: UpdateStringFieldValues) => {
    await tagCampaignLeadProfileMutation({
      campaignLeadProfileId: campaignLeadProfileId,
      note: data.field,
      propertyValues: [],
      saleActionStatuses: [],
    });
    setIsEditingField(undefined);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4  space-y-4">
        <div>{errors.field && <ErrorMessage>{errors.field.message}</ErrorMessage>}</div>
        <Label htmlFor="note" className="sr-only">
          Update note
        </Label>
        <Textarea
          {...register("field")}
          defaultValue={existingNote}
          rows={4}
          placeholder="Update note..."
        />
        <div className="mt-6 flex justify-between">
          <Button
            onClick={() => setIsEditingField(undefined)}
            type="button"
            className="mt-2 w-full sm:mt-0 sm:w-fit"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={tagCampaignLeadProfileMutationLoading}
            className="w-full sm:w-fit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditNote;
