export enum WORKSPACE_STATUS {
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
}

export enum OUTREACH_PLATFORM {
  LINKEDIN = 1,
  FACEBOOK = 2,
  EMAIL = 3,
}

export enum SALE_ACTION_STATUS {
  DEAL_LOST = 1,
  QUALIFIED = 2,
  INTERESTED = 3,
  CALL_BOOKED = 4,
  VALUATION_WON = 5,
  INSTRUCTION_WON = 6,
  SSTC = 7,
  SOLD = 8,
  UNKNOWN = 9,
  UNDER_OFFER = 10,
  QUALIFIED_LOW_INTEREST = 11,
}

export enum DIGITAL_PROFILE_TYPE {
  EMAIL = 1,
  NUMBER = 2,
  LINKEDIN = 3,
  INSTAGRAM = 4,
  FACEBOOK = 5,
}

export enum CAMPAIGN_INTERNAL_STATUS {
  HISTORICAL = 1,
  NEW = 2,
  ADDRESSES_REQUESTED = 3,
  ADDRESSES_OBTAINED = 4,
  ADDRESSES_CLEANED = 5,
  OWNERS_REQUESTED = 6,
  OWNERS_OBTAINED = 7,
  OWNERS_CLEANED = 8,
  PROFILES_REQUESTED = 9,
  PROFILES_OBTAINED = 10,
  PROFILES_CLEANED = 11,
  OWNERS_MATCHED = 12,
  PROFILES_MATCHED = 13,
  PROFILES_SELECTED = 14,
}

export enum SALE_ACTION_STATUS_SOURCE {
  AI = 1,
  AGENT = 2,
  ADMIN = 3,
}

export enum CAMPAIGN_TYPE {
  SLOW_MOVER = 1,
  WITHDRAWN = 2,
  CUSTOM_CSV = 3,
  TWENTY_TWENTY = 4,
  REDUCED = 5,
  UNDER_OFFER = 6,
  ON_BEHALF_OF_BUYER = 7,
  FALL_THROUGH = 8,
  CUSTOM_SEARCH = 9,
  DOOR_KNOCKING = 10,
  NEW = 11,
}

export enum CAMPAIGN_AUTOMATION_LEVEL {
  GET_ADDRESSES = 1,
  MATCH_ADDRESSES = 2,
  CLEAN_ADDRESSES = 3,
  GET_OWNERS = 4,
  MATCH_OWNERS = 5,
  CLEAN_OWNERS = 6,
  GET_PROFILES = 7,
  CLEAN_PROFILES = 8,
  MATCH_PROFILES = 9,
}

export enum CAMPAIGN_LEAD_PROFILE_OUTREACH_STATUS {
  DISCOVERED = 1,
  CONNECTION_PENDING = 2,
  CONNECTED = 3,
  REPLIED = 4,
}
