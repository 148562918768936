import Logo from "../../../../shared/assets/logo.svg";

const FormSubmitted = () => {

  return (
    <div className="min-h-screen flex-wrap flex flex-col items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Meetmore" />
          <p className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Thank you for filling out the form! <br></br>We will be in contact with you shortly.
          </p>{" "}
        </div>
    	</div>
    </div>
  );
};

export default FormSubmitted;
