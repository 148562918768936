import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

type Payload = {
  linkedInEmail: string,
  linkedInPassword: string
  email: string,
  cellNumber: string,
  postcodeDistricts: string
};

export const sendLinkedInCredentialsAPI = async ({ linkedInEmail, linkedInPassword, email, cellNumber, postcodeDistricts }: Payload) => {
  const payload = {
    linkedInEmail,
    linkedInPassword,
    email,
    cellNumber,
    postcodeDistricts
  };

  const response = await meetmoreAPI.post(
    `/workspaces/send-new-client-linkedin-credentials`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const useSendLinkedinCredendials = ({
  showToasts = false, onSuccessCallback
}: {
  showToasts?: boolean;
  onSuccessCallback: () => Promise<void>;
}) => {
  let loadingToastId: string;

  return useMutation(sendLinkedInCredentialsAPI, {
    onMutate: () => {
      if (showToasts) {
        loadingToastId = toast.loading("Sending credentials...");
      }
    },
    onSuccess: async (data, variables, context) => {
      if (showToasts) {
        toast.success("Credentials submitted");
      }
      await onSuccessCallback(); 
    },
    onError: (error: any, variables, context) => {
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
