import { useLazyQuery } from "@apollo/client";
import { GetProspectedCampaignLeadProfilesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getProspectedCampaignLeadProfiles } from "../queries/getProspectedCampaignLeadProfiles";

export type UseProspectedCampaignLeadProfiles = ReturnType<
  typeof useProspectedCampaignLeadProfiles
>;

export const useProspectedCampaignLeadProfiles = () => {
  const [fetchProspectedCampaignLeadProfiles, { data, loading, error }] =
    useLazyQuery<GetProspectedCampaignLeadProfilesQuery>(getProspectedCampaignLeadProfiles);

  const prospectedCampaignLeadProfiles =
    data && Array.isArray(data.campaign_lead_profile)
      ? data.campaign_lead_profile.map(campaignLeadProfile => {
          const messages = campaignLeadProfile.conversation_thread?.conversation_messages ?? [];
          const numberOfUnreadMessages = messages.filter(
            message => !message.admin_seen_at && message.is_outbound === false
          ).length;

          const lastInboundMessage =
            Array.isArray(campaignLeadProfile.conversation_thread?.last_inbound_message) &&
            campaignLeadProfile.conversation_thread?.last_inbound_message[0]
              ? campaignLeadProfile.conversation_thread?.last_inbound_message[0]
              : null;

          return {
            ...campaignLeadProfile,
            numberOfUnreadMessages,
            lastReplyExternalCreatedAt: lastInboundMessage?.external_created_at,
          };
        })
      : [];

  return {
    prospectedCampaignLeadProfiles: prospectedCampaignLeadProfiles,
    prospectedCampaignLeadProfilesCount:
      data?.campaign_lead_profile_aggregate.aggregate?.count ?? 0,
    prospectedCampaignLeadProfilesError: error,
    prospectedCampaignLeadProfilesLoading: loading,
    fetchProspectedCampaignLeadProfiles,
  };
};
