import {
  InformationCircleIcon
} from "@heroicons/react/24/solid";

import {
  Control,
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import Select from "react-select";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { UsePostcodeDistricts } from "../../../../shared/domains/postcodes/graphql/hooks/usePostcodeDistricts";
import { reactSelectStyling } from "../../../../shared/styles";
import { NewClientValues } from "../validation";

interface OnMarketFiltersProps {
  nextStep: () => Promise<void>;
  previousStep: () => Promise<void>;
  register: UseFormRegister<NewClientValues>;
  setValue: UseFormSetValue<NewClientValues>;
  errors: FieldErrorsImpl<DeepRequired<NewClientValues>>;
  watch: UseFormWatch<NewClientValues>;
  control: Control<NewClientValues, any>;
  trigger: UseFormTrigger<NewClientValues>;
  currentStep: number;
  postcodeDistricts: UsePostcodeDistricts["postcodeDistricts"];
}

export default function OnMarketFilters({
  previousStep,
  errors,
  currentStep,
  trigger,
  control,
  nextStep,
  postcodeDistricts,
}: OnMarketFiltersProps) {
    const validateAndNext = async () => {
    const results = await trigger(["postcodeDistricts"]);
    if (results) {
      nextStep();
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen h-full flex flex-col items-center justify-center focus:outline-none">
      <div className="relative w-full max-w-3xl mx-auto space-y-8">
        <h2 className="text-center text-3xl font-extrabold text-gray-900">
          {currentStep}. Which addresses do you want to{" "}
          <span className="text-orange-500">target</span>?
        </h2>
        <div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-orange-700">
                Let us know which districts you’re interested in, and we’ll confirm their availability. We work exclusively with one agent per postcode district. MeetMore is free to join, and you only pay upon successful completion.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded-lg px-4 py-6 sm:p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Postcode District</label>
            <span className="text-gray-500 text-xs">Please select postcode districts for market addresses</span>
            <div className="mt-2">
              <Controller
                name="postcodeDistricts"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={reactSelectStyling}
                    maxMenuHeight={220}
                    options={postcodeDistricts.map((postcodeDistrict) => ({
                      label: postcodeDistrict.code,
                      value: postcodeDistrict.id,
                    }))}
                    isSearchable
                    isMulti
                    placeholder="Select Postcode District"
                  />
                )}
              />
            </div>
          </div>

          {errors.postcodeDistricts && (
            <ErrorMessage>Please select at least one Postcode District</ErrorMessage>
          )}
        </div>
        <div className="flex space-x-6">
          <button
            type="button"
            disabled={true}
            onClick={() => previousStep()}
            className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => validateAndNext()}
            className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
