import { UseFormRegister, UseFormSetValue, FieldErrorsImpl, UseFormTrigger, UseFormHandleSubmit, UseFormClearErrors } from "react-hook-form";
import { NewClientValues } from "../validation";
import LinkedInLogo from '../../../../shared/assets/linkedin-logo.png';

interface LinkedInCredentialsProps {
  currentStep: number;
  nextStep: () => Promise<void>;
  previousStep: () => Promise<void>;
  setValue: UseFormSetValue<NewClientValues>;
  register: UseFormRegister<NewClientValues>;
  errors: FieldErrorsImpl<NewClientValues>;
  trigger: UseFormTrigger<NewClientValues>;
  onSubmit: (values: NewClientValues) => void;
  handleSubmit: UseFormHandleSubmit<NewClientValues>;
  clearErrors: UseFormClearErrors<NewClientValues>;
}

const LinkedInCredentials = ({ currentStep, clearErrors, handleSubmit, onSubmit, nextStep, previousStep, setValue, register, errors, trigger }: LinkedInCredentialsProps) => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg px-8 py-10">
        <h2 className="text-center text-3xl font-bold text-blue-700 mb-6">
        {currentStep}. Add LinkedIn Account
        </h2>
        <div className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              LinkedIn Email
            </label>
            <input
              id="email"
              type="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="you@example.com"
              {...register("linkedInEmail", { required: "LinkedIn email is required" })}
            />
            {errors.linkedInEmail && <p className="mt-2 text-sm text-red-600">{errors.linkedInEmail.message}</p>}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              LinkedIn Password
            </label>
            <input
              id="password"
              type="password"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Enter your password"
              {...register("linkedInPassword", { required: "LinkedIn password is required" })}
            />
            {errors.linkedInPassword && <p className="mt-2 text-sm text-red-600">{errors.linkedInPassword.message}</p>}
          </div>

          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="confirmation"
                type="checkbox"
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                {...register("linkedinConfirmation", { required: "Confirmation is required" })}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="confirmation" className="font-medium text-gray-700">
                I confirm that I have access to the email of the LinkedIn account entered above (LinkedIn will send a security verification code to the provided email address).
              </label>
            </div>
          </div>
          {errors.linkedinConfirmation && (
            <p className="mt-2 text-sm text-red-600">{errors.linkedinConfirmation.message}</p>
          )}

          <div className="flex space-x-6">
            <button
              type="button"
              onClick={() => previousStep()}
              className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"
            >
              Back
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <img src={LinkedInLogo} alt="LinkedIn Logo" className="w-40 h-auto" />
      </div>
    </div>
  );
};

export default LinkedInCredentials;
