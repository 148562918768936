import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { usePostcodeDistricts } from "../../../shared/domains/postcodes/graphql/hooks/usePostcodeDistricts";
import { useSendLinkedinCredendials } from "../../../shared/domains/workspaces/REST/sendNewClientLinkedInCredentialsAPI";
import { useSendPostcodeDistrictAndContactDetails } from "../../../shared/domains/workspaces/REST/sendNewClientPostCodesAndContactDetailsAPI";
import useScroll from "../../../shared/hooks/UseScroll";
import { cx } from "../../../shared/utils";
import ContactDetails from "./components/ContactDetails";
import FormSubmitted from "./components/FormSubmitted";
import LinkedInCredentials from "./components/LinkedInCredentials";
import LinkedInDisclaimer from "./components/LinkedInDisclaimer";
import OnMarketFilters from "./components/OnMarketFilters";
import { NewClientValues, newClientSchema } from "./validation";

const NewClient = () => {
  const { postcodeDistricts } = usePostcodeDistricts();
  const [currentStep, setCurrentStep] = useState(0);

  const {
    watch,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    register,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<NewClientValues>({
    resolver: yupResolver(newClientSchema)
  });

  const nextStep = async () => {
    setCurrentStep(prev => prev + 1);
  };
  const previousStep = async () => {
    setCurrentStep(prev => prev - 1);
  };

  const { mutateAsync: sendLinkedInCredentialsMutation } =
  useSendLinkedinCredendials({
    showToasts: true,
    onSuccessCallback: nextStep,
  });

  const { mutateAsync: sendPostcodeDistrictAndContactDetailsMutation } =
  useSendPostcodeDistrictAndContactDetails({
    showToasts: true,
    onSuccessCallback: nextStep,
  });

  const sendLinkedInCredentials = (values: NewClientValues) => {
    const { linkedInEmail, linkedInPassword, email, postcodeDistricts, cellNumber } = values;
    const flattenPostcodeDistricts = postcodeDistricts.map((postcodeDistrict) => postcodeDistrict.label).join(",");

    sendLinkedInCredentialsMutation({ linkedInEmail, linkedInPassword, email, postcodeDistricts: flattenPostcodeDistricts, cellNumber });
  };

  const sendPostcodeDistrictAndContactDetails = (values: NewClientValues) => {
    const { email, postcodeDistricts, cellNumber } = values;
    const flattenPostcodeDistricts = postcodeDistricts.map((postcodeDistrict) => postcodeDistrict.label).join(",");

    sendPostcodeDistrictAndContactDetailsMutation({ email, postcodeDistricts: flattenPostcodeDistricts, cellNumber });
  };

  const scrolled = useScroll(15);

  return (
    <>
      <header
        className={cx(
          "fixed inset-x-0 top-0 isolate z-50 flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 transition-all md:grid md:grid-cols-[200px_auto_200px] md:px-6 dark:border-gray-900 dark:bg-gray-925",
          scrolled ? "h-12" : "h-20"
        )}
      >
        <div className="hidden flex-nowrap items-center gap-0.5 md:flex" aria-hidden="true">
          <span className="mt-0.5 text-lg font-semibold text-gray-900 dark:text-gray-50">
            New Client
          </span>
        </div>
      </header>

      <form onSubmit={handleSubmit(sendLinkedInCredentials)}>
        {currentStep === 0 && (
          <OnMarketFilters
          postcodeDistricts={postcodeDistricts}
          currentStep={currentStep + 1}
          setValue={setValue}
          watch={watch}
          previousStep={previousStep}
          register={register}
          control={control}
          errors={errors}
          nextStep={nextStep}
          trigger={trigger}
        />
        )}

        {currentStep === 1 && (
          <ContactDetails
            currentStep={currentStep + 1}
            nextStep={nextStep}
            previousStep={previousStep}
            setValue={setValue} 
            register={register}
            errors={errors}
            trigger={trigger}
            getValues={getValues}
            onSubmitPostalCodesAndContactDetails={sendPostcodeDistrictAndContactDetails}
          />
        )}

        {currentStep === 2 && (
          <LinkedInDisclaimer
            currentStep={currentStep + 1}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        )}

        {currentStep === 3 && (
          <LinkedInCredentials
            currentStep={currentStep + 1}
            nextStep={nextStep}
            previousStep={previousStep}
            setValue={setValue} 
            register={register}
            errors={errors}
            trigger={trigger}
            onSubmit={sendLinkedInCredentials}
            clearErrors={clearErrors}
            handleSubmit={handleSubmit}
          />
        )}
        {currentStep === 4 && (
          <FormSubmitted />
       )}
      </form>
    </>
  );
};

export default NewClient;
