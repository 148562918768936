import { gql } from "@apollo/client";

export const getUserWorkspacesByExternalId = gql`
  query getUserWorkspacesByExternalId($externalId: String!) {
    workspace_user(
      where: { user: { external_id: { _eq: $externalId } } }
    ) {
      user_id
      workspace_id
      workspace {
        workspace_status_id
      }
    }
  }
`;
