import * as yup from 'yup';

export interface NewClientValues {
  postcodeDistricts: { label: string; value: number }[];
  linkedInEmail: string;
  linkedInPassword: string;
  linkedinConfirmation: boolean;
  email: string;
  cellNumber: string;
};

export const newClientSchema = yup.object().shape({
postcodeDistricts: yup
    .array()
    .of(
    yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .typeError("Please select at least one Postcode District")
    )
    .min(1, "Please select at least one Postcode District")
    .required("Please select at least one Postcode District")
    .defined("Please select at least one Postcode District"),

linkedInEmail: yup
  .string()
  .email("Please enter a valid LinkedIn email address")
  .required("LinkedIn email is required"),

linkedInPassword: yup
  .string()
  .required("LinkedIn password is required"),

linkedinConfirmation: yup
  .boolean()
  .oneOf([true], "You must confirm that you have access to the email")
  .required("Confirmation is required"),

email: yup
  .string()
  .required("Email is required"),

cellNumber: yup
  .string()
  .required("Mobile number is required"),
});
